/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotografie"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-18domqa pb--60 pt--80" name={"uvod-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/25609/741972a972cc48f1ac275521e478168b_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" style={{"maxWidth":""}} content={"<span style=\"color: var(--color-dominant);\">Fotografie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--16" name={"menu"} style={{"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center fs--18 w--500 ls--02" content={"<a href=\"/fotografie\" style=\"color: rgb(252, 113, 13);\">Interiér</a>&nbsp;<span style=\"color: var(--color-blend--50);\"> |&nbsp;</span> <a href=\"/fotografie-exterier\" style=\"color: var(--color-blend--15);\">Exteriér</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"0898e80032cj"} style={{"paddingTop":46}} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Ložnice B - dvojlůžko (manželská postel)"} src={"https://cdn.swbpg.com/t/2124/ffebed28897a4a90847ad8f0c4bb3f8c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/ffebed28897a4a90847ad8f0c4bb3f8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/ffebed28897a4a90847ad8f0c4bb3f8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/ffebed28897a4a90847ad8f0c4bb3f8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/ffebed28897a4a90847ad8f0c4bb3f8c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/ffebed28897a4a90847ad8f0c4bb3f8c_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Ložnice B - dvojlůžko (manželská postel)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/b95558996b0f4f9d946abd108e31bfeb_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/b95558996b0f4f9d946abd108e31bfeb_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/b95558996b0f4f9d946abd108e31bfeb_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/b95558996b0f4f9d946abd108e31bfeb_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/b95558996b0f4f9d946abd108e31bfeb_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/b95558996b0f4f9d946abd108e31bfeb_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Ložnice D - jedno dvoulůžko (manželská postel) a dvě jedno lůžka"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/0cd17e976bc5465db3f486f4ed69ae41_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/2124/0cd17e976bc5465db3f486f4ed69ae41_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/0cd17e976bc5465db3f486f4ed69ae41_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/0cd17e976bc5465db3f486f4ed69ae41_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/0cd17e976bc5465db3f486f4ed69ae41_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/0cd17e976bc5465db3f486f4ed69ae41_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Ložnice D - jedno dvoulůžko (manželská postel) a dvě jedno lůžka"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/85e946be3eca4377ace6cbf7456f3b28_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/85e946be3eca4377ace6cbf7456f3b28_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/85e946be3eca4377ace6cbf7456f3b28_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/85e946be3eca4377ace6cbf7456f3b28_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/85e946be3eca4377ace6cbf7456f3b28_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/85e946be3eca4377ace6cbf7456f3b28_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Chodba 1 NP - pohled na vstup do ložnice B, C a koupelny"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/c796509d855a42aaaca0f031362b79d8_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/c796509d855a42aaaca0f031362b79d8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/c796509d855a42aaaca0f031362b79d8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/c796509d855a42aaaca0f031362b79d8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/c796509d855a42aaaca0f031362b79d8_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/c796509d855a42aaaca0f031362b79d8_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Koupelna 1 NP"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/e3607ec110ed43afbec2ee8d0ba204b2_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/e3607ec110ed43afbec2ee8d0ba204b2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/e3607ec110ed43afbec2ee8d0ba204b2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/e3607ec110ed43afbec2ee8d0ba204b2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/e3607ec110ed43afbec2ee8d0ba204b2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/e3607ec110ed43afbec2ee8d0ba204b2_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Schodiště"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/a91886d7011e466ea29686a4c6013ae7_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/a91886d7011e466ea29686a4c6013ae7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/a91886d7011e466ea29686a4c6013ae7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/a91886d7011e466ea29686a4c6013ae7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/a91886d7011e466ea29686a4c6013ae7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/a91886d7011e466ea29686a4c6013ae7_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Technická místnost a sušárna"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/df7ec6c1909b41cb879ac33b5495c493_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/df7ec6c1909b41cb879ac33b5495c493_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/df7ec6c1909b41cb879ac33b5495c493_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/df7ec6c1909b41cb879ac33b5495c493_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/df7ec6c1909b41cb879ac33b5495c493_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/df7ec6c1909b41cb879ac33b5495c493_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Koupelna přízemí"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/7a03a87d0d334b3490a887b30a2a3f8c_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/7a03a87d0d334b3490a887b30a2a3f8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/7a03a87d0d334b3490a887b30a2a3f8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/7a03a87d0d334b3490a887b30a2a3f8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/7a03a87d0d334b3490a887b30a2a3f8c_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/7a03a87d0d334b3490a887b30a2a3f8c_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Koupelna přízemí"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/2c84c5dae78c4c75b7aba3f33675d23d_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":""}} srcSet={"https://cdn.swbpg.com/t/2124/2c84c5dae78c4c75b7aba3f33675d23d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/2c84c5dae78c4c75b7aba3f33675d23d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/2c84c5dae78c4c75b7aba3f33675d23d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/2c84c5dae78c4c75b7aba3f33675d23d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/2c84c5dae78c4c75b7aba3f33675d23d_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Obývací pokoj"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/25609/afcb987bdf6646f892afb9a914451311_s=660x_.JPG"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/25609/afcb987bdf6646f892afb9a914451311_s=350x_.JPG 350w, https://cdn.swbpg.com/t/25609/afcb987bdf6646f892afb9a914451311_s=660x_.JPG 660w, https://cdn.swbpg.com/t/25609/afcb987bdf6646f892afb9a914451311_s=860x_.JPG 860w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Ložnice A - dvojlůžko (rozkládací pohovka)"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/5d9ecd1af99344cfa21ab10027c0f8fa_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/5d9ecd1af99344cfa21ab10027c0f8fa_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/5d9ecd1af99344cfa21ab10027c0f8fa_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/5d9ecd1af99344cfa21ab10027c0f8fa_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/5d9ecd1af99344cfa21ab10027c0f8fa_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/5d9ecd1af99344cfa21ab10027c0f8fa_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Kuchyně a jídelna"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/c9cebc29fa22452dbc0252fdbecab1d4_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/c9cebc29fa22452dbc0252fdbecab1d4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/c9cebc29fa22452dbc0252fdbecab1d4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/c9cebc29fa22452dbc0252fdbecab1d4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/c9cebc29fa22452dbc0252fdbecab1d4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/c9cebc29fa22452dbc0252fdbecab1d4_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Kuchyně"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/2ea6b34ebdab42958ef1cf007610c5c4_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/2ea6b34ebdab42958ef1cf007610c5c4_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/2ea6b34ebdab42958ef1cf007610c5c4_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/2ea6b34ebdab42958ef1cf007610c5c4_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/2ea6b34ebdab42958ef1cf007610c5c4_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/2ea6b34ebdab42958ef1cf007610c5c4_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Jídelna, obývací pokoj"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/1167a3d929ea4afea8227128821d08ed_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/1167a3d929ea4afea8227128821d08ed_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/1167a3d929ea4afea8227128821d08ed_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/1167a3d929ea4afea8227128821d08ed_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/1167a3d929ea4afea8227128821d08ed_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/1167a3d929ea4afea8227128821d08ed_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Obývací pokoj, schodiště"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Popis obrázku"} src={"https://cdn.swbpg.com/t/2124/36561fe38ece40e2ac85bc3330dc04c2_s=860x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/2124/36561fe38ece40e2ac85bc3330dc04c2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2124/36561fe38ece40e2ac85bc3330dc04c2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2124/36561fe38ece40e2ac85bc3330dc04c2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2124/36561fe38ece40e2ac85bc3330dc04c2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2124/36561fe38ece40e2ac85bc3330dc04c2_s=2000x_.jpg 2000w"}>
              </Image>

              <Text className="text-box text-box--left" content={"Chodba do společenského prostoru"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--25" name={"paticka"} style={{"backgroundColor":"rgba(21,21,22,1)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --left el--2" anim={"2"} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":560}}>
              
              <Subtitle className="subtitle-box fs--20 w--600" content={"<span style=\"color: var(--color-custom-2);\">Chalupavojtechov.cz</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2);\">Vojtěchov č. ev. 107<br>593 01 Lísek<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box fs--20 lh--16" content={"<br><span style=\"color: rgb(201, 201, 201);\">+420</span> <span style=\"color: var(--color-custom-2);\">774 344 014</span><br><a href=\"mailto:nikola@chalupavojtechov.cz?subject=Zpr%C3%A1va+z+webu\"><span style=\"color: rgb(201, 201, 201);\">nikola</span><span style=\"color: var(--color-custom-2);\">@chalupavojtechov.cz</span></a><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}